import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { auth, RegisterWithEmailAndPassword, SignInWithApple, SignInWithFacebook, SignInWithGoogle, users, privateUsers } from "../../utils/firebase.utils";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import * as queryString from "query-string";
import Form from "../../components/forms/form-wrapper";
import FormInput from "../../components/forms/form-input";
import Text from "../../components/data-display/text";
import AuthContainer from "../../components/data-display/AuthContainer";
import Button from "../../components/buttons/primary-button";
import InlineLink from "../../components/data-display/inline-link";
import GoogleIcon from "../../images/icons/google.png";
import FacebookIcon from "../../images/icons/facebook.png";
import AppleIcon from "../../images/icons/apple.png";
import FormErrorText from "../../components/data-display/form-error-text";
import SecondaryButton from "../../components/buttons/secondary-button";
import { RootState } from "../../redux/index.reducers";
import PlayStore from "../../images/icons/google_playstore.svg";
import AppStore from "../../images/icons/apple-appstore.svg";
import { UserData } from "../../models/user-data";
import { navigate, Link } from "gatsby";
import {
  baseUrl,
  isQuickTaskProject,
  project,
  title,
  appStoreLink,
  GooglePlayLink,
} from "../../utils/constant.urls";
import SEO from "../../components/seo/seo";
import { environment } from "../../environments/quickTaskEnvironemt";
import { Router, useLocation } from "@reach/router";
import { NoAuthRoute } from "../../components/app-routes/app-routes";

const QuikTaskMainColor = environment.mainColor;

function Register({ location }) {
  const theme = useTheme();
  const styles = {
    background: {
      display: "flex",
      background: theme.palette.background.default,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
  };
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const [error, setError] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState<string>("");
  const { isAuthenticated } = useSelector((state: RootState) => state.userData);

  useEffect(() => {
    auth.getRedirectResult().then(async (result) => {
      if (result.user) {
        const user = result.user;
        const userDoc = await users.doc(user.uid).get();
        const userData = userDoc.data();
        if (userData && userData.project === project) {
          setAuthenticated(true);
        } else {
          setError("You don't have access to this project.");
          navigate("/register");
        }
      }
    }).catch((error) => {
      console.log(error);
      setError(error.message);
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) setAuthenticated(true);
  }, [isAuthenticated]);

  const handleUserAuthentication = async (result, sendEmail = false) => {
    if (result.user) {
      const user = result.user;
      const userData: UserData = new UserData({
        uid: user.uid,
        email: user.email,
        firstName: user.displayName?.split(" ")[0] || "",
        lastName: user.displayName?.split(" ")[1] || "",
        project,
      });

      await users.doc(user.uid).set(userData.toPublicData());
      await privateUsers.doc(userData.uid).set({ project }, { merge: true });

      if (sendEmail) {
        const newUser = auth.currentUser;

        axios.post(`${baseUrl}/sendVerificationEmail?project=${project}`, {
          email: newUser.email,
        })
        .then(() => {
          console.log("Verification email sent successfully");
        })
        .catch((err) => {
          console.log("Error sending verification email:", err.code);
        });
      }

      setAuthenticated(true);
    } else {
      setError("Something went wrong on our side.");
    }
  };

  const loginWithGoogle = () => {
    setLoading("googleLogin");
    SignInWithGoogle().then((result) => handleUserAuthentication(result)).catch((err) => {
      setError(err.message);
      setLoading("");
    });
  };

  const loginWithFacebook = () => {
    setLoading("facebookLogin");
    SignInWithFacebook().then((result) => handleUserAuthentication(result)).catch((err) => {
      setError(err.message);
      setLoading("");
    });
  };

  const loginWithApple = () => {
    setLoading("appleLogin");
    SignInWithApple().then((result) => handleUserAuthentication(result)).catch((err) => {
      setError(err.message);
      setLoading("");
    });
  };

  const register = (values) => {
    return new Promise((resolve, reject) => {
      const numberRegx = /\d/;
      const upperCaseRegx = /[A-Z]/;
      const symbolRegx = /[@$!%*#?&+\-\/]/;
      
    const firstName = values?.firstName?.trim();
    const lastName = values?.lastName?.trim();
    const email = values?.email?.trim();
    const newPassword = values?.newPassword?.trim();

      const fullname = firstName + " " + lastName;

      if ( !email || !newPassword) {
        return reject("All fields are required.");
      }

      if (fullname.length > 25) {
        return reject("Full name must be less than or equal to 25 characters");
      }

      if (newPassword.length < 8) {
        return reject({ newPassword: "Must be 8+ characters" });
      }

      if (!numberRegx.test(newPassword)) {
        return reject({ newPassword: "Must contain at least one number" });
      }

      if (!upperCaseRegx.test(newPassword)) {
        return reject({ newPassword: "Must contain at least one uppercase letter" });
      }

      if (!symbolRegx.test(newPassword)) {
        return reject({ newPassword: "Must contain at least one symbol" });
      }

      setLoading("register");

      RegisterWithEmailAndPassword(email, newPassword).then(async (result) => {
        await handleUserAuthentication(result, true); // Pass true to send email
        resolve("success");
      }).catch((err) => {
        setLoading("");
        switch (err.code) {
          case "auth/email-already-in-use":
            return reject({ email: "Account already exists." });
          case "auth/weak-password":
            return reject({
              password: "Password must contain 8+ characters, a symbol & a number.",
            });
          case "auth/invalid-email":
            return reject({ email: "Please enter a valid Email Address." });
          default:
            return reject("Something went wrong on our side.");
        }
      });
    });
  };

  return (
    <>
      <SEO title={`Register`} pathname={"/register"} description={""} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh", background: theme.palette.background.default }}>
        <div
          style={{
            ...(styles.background as React.CSSProperties),
          }}
        >
          {authenticated &&
            navigate(
              queryString.parse(location.search).redirect
                ? (queryString.parse(location.search).redirect as string)
                : "/tasks"
            )}
          <div style={{ marginTop: xsDown ? "45px" : "160px" }} />
          <Grid justifyContent={"center"} component={"div"} container>
            <Grid component={"div"} xl={5} lg={5} md={6} sm={6} xs={12} item style={{ display: "flex", justifyContent: "center" }}>
              <AuthContainer padding>
                <Form initialValues={{ email: "", firstName: "", lastName: "", newPassword: "" }} disableLoading onSubmit={register} style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginBottom: "8px" }}>
                    <Text variant={"h5"}>Sign Up</Text>
                    <Text variant={"body2"}>
                      <span style={{ opacity: "0.6", marginRight: "8px" }}>Have an account?</span>
                      <Text variant={"body2"} medium component={"span"}>
                        <InlineLink to={"/login"} style={{ color: palette_Color }} variant={"primary"}>Sign In</InlineLink>
                      </Text>
                    </Text>
                  </div>
                  <div style={{ display: "flex" }}>
                    <FormInput placeholder={"First Name"} name={"firstName"} type={"text"} />
                    <div style={{ width: "16px" }} />
                    <FormInput placeholder={"Last Name"} name={"lastName"} type={"text"} />
                  </div>
                  <FormInput placeholder={"Enter your email"} name={"email"} type={"email"} />
                  <FormInput placeholder={"Set Password"} name={"newPassword"} type={"password"} />
                  <FormErrorText error={error} showError={error !== ""} />
                  <Button type={"submit"} style={{ marginTop: "6px", backgroundColor: palette_Color }} isLoading={loading === "register"}>Join {title}</Button>
                  <div style={{ display: "flex", margin: "8px 5%", alignItems: "center" }}>
                    <div style={{ flex: "1", height: "2px", background: "grey", opacity: "0.1" }} />
                    <Text variant={"body2"} medium component={"span"} style={{ color: "grey", margin: "0 12px" }}>or</Text>
                    <div style={{ flex: "1", height: "2px", background: "grey", opacity: "0.1" }} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <SecondaryButton type="button" isLoading={loading === "appleLogin"} background={"rgb(10, 10, 10)"} onClick={loginWithApple} textColor={"white"} icon={AppleIcon}>Continue with Apple</SecondaryButton>
                    <div style={{ height: "10px" }} />
                    <SecondaryButton type={"button"} background={"rgb(66, 103, 178)"} isLoading={loading === "facebookLogin"} onClick={loginWithFacebook} textColor={"white"} icon={FacebookIcon}>Continue with Facebook</SecondaryButton>
                    <div style={{ height: "10px" }} />
                    <SecondaryButton type={"button"} background={"white"} isLoading={loading === "googleLogin"} onClick={loginWithGoogle} textColor={"rgb(40,40,40)"} icon={GoogleIcon}>Continue with Google</SecondaryButton>
                  </div>
                  <div style={{ height: "12px" }} />
                  <Text variant={"caption"} medium component={"span"} style={{ color: "grey", width: "80%", margin: "0 auto" }}>
                    By clicking Join {title}, Continue with Apple, Continue with Facebook or Continue with Google, you agree to our{" "}
                    <InlineLink to={"/terms"} style={{ color: palette_Color }} variant={"primary"}>Terms & Conditions</InlineLink> and{" "}
                    <InlineLink to={"/privacy-policy"} style={{ color: palette_Color }} variant={"primary"}>Privacy Policy</InlineLink>
                  </Text>
                </Form>
                {xsDown && (
                  <div style={{ display: "flex", position: "relative", width: "100%", justifyContent: "center", marginTop: "22px" }}>
                    <a href={GooglePlayLink}>
                      <img loading={"lazy"} src={PlayStore} alt={""} />
                    </a>
                    <div style={{ width: "18px" }} />
                    <a href={appStoreLink}>
                      <img loading={"lazy"} src={AppStore} alt={""} />
                    </a>
                  </div>
                )}
              </AuthContainer>
            </Grid>
          </Grid>
          <div style={{ marginTop: xsDown ? "45px" : "85px" }} />
        </div>
      </div>
    </>
  );
}

const App = () => {
  const location = useLocation();
  return (
    <div className="content">
      <Router location={location}>
        <NoAuthRoute path={"/register"} component={Register} />
      </Router>
    </div>
  );
};

export default App;
